<template>
    <section>
      <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-sm-12">
            <!-- Mashead text and app badges-->
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h2 class="display-5 lh-1 mb-3 title-bold">
                Darse de Baja de Notificaciones por Email
              </h2>
              <h3 v-if="!completed" class="1h-1">
                Si desea darse de baja del servicio de notificaciones por correo electrónico, favor de digitar su correo y seleccionar el botón Enviar Petición.
              </h3>
              <h3 v-if="completed" class="1h-1">
                Dado de baja exitosamente.
              </h3>
            </div>
          </div>
        </div>
        <validation-observer ref="ModalRef" novalidate>
        <validation-provider
          v-slot="{ errors }"
          class="col-sm-12 col-md-4"
          :rules="{
            email: true,
            required: true,
            max:64
          }"
        >
            <div v-if="!completed" class="form-group row">
              <div class="col-sm-12">
                <base-input
                  v-model="email"
                  :error="errors[0]"
                  error-msg="Un correo electrónico válido es requerido"
                  class="form-control-lg font-xxl"
                />
              </div>
            </div>
          </validation-provider>
        </validation-observer>
        <div v-if="!completed" class="row text-center mt-3">
          <button
            class="btn btn-continue"
            type="button"
            @click="unsubscribe()"
          >
            Enviar Petición<i class="fas fa-circle-arrow-right" />
          </button>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import BaseInput from '@/components/BaseInput.vue';
  import loading from '@/mixins/vue-loading';
  
  import {
      unsubscribeEmail as _unsubscribeEmail
  } from '@/services/NotificationService';
  
  export default {
      components: {
          BaseInput
      },
      mixins: [loading],
      data() {
          return {
            email: null,
            completed: false
          }
      },
      methods: {
          async unsubscribe() {
            if (!(await this.$refs.ModalRef.validate())) return;

            this.ShowWait();

            await _unsubscribeEmail({
                email: this.email
            }).then(() => {
                this.completed = true;
            }).finally(() => {
                this.HideWait();
            })
          }
      }
  }
  </script>
  
  <style scoped>
      ::v-deep input[type='text'] {
         text-align: center;
    }

    .container {
        min-height: 564px;
    }

    .btn-continue {
        background-color: #e9b563;
        color: #fff;
        margin: auto;
    }
    
    .btn-continue:hover {
        background-color: #c64819;
        color: #fff;
    }

    ::v-deep .error-container p {
      font-size: 1.2rem;
    }

    ::v-deep .error-container > div {
      text-align: center!important;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 1.2rem;
        }
    }
  </style>