<template>
  <div class="container-md">
    <Loader v-if="loading" />
    <div class="row">
      <div v-if="sessionCompleted" class="col-md-12 text-center">
        <h2>{{ $t('sessionConcluded') }}</h2>
        <hr />
        <h4>{{ $t('externalChatSessionCompleteMessage') }}</h4>
      </div>

    </div>
    <div v-if="externalChatRoom && !sessionCompleted" class="row">
      <div class="col-md-12 d-flex text-center">
        <h5 class="w-100" style="font-weight: bold">{{ startMessage }}</h5>
      </div>
      <div class="col-md-12 d-flex conversation">
        <Chat class="manager-chat ext" :show-info-notifications="['info-noti-citizen']" :room="externalChatRoom" />
      </div>
      <div class="col-12 mt-3 text-right">
        <button class="btn btn-warning btn-block" @click="showDropModal = true">
          {{ $t('endSession') }}
        </button>
      </div>
    </div>
    <confirmation-modal
      v-model="showDropModal"
      text="areYouSureYouWantToEndSession"
      @on-confirm="onDrop()"
      @on-reject="showDropModal = false"
    />
    <custom-modal
      v-model="requireRecordingDisclaimerApproval"
      id="mdRecording"
      ref="mdRecording-ref"
      :no-close-on-back-drop="true"
      size="md"
    >
      <div class="back-container text-center">
        <i class="far fa-broadcast-tower fa-3x" style="color: #ff8105" />

        <div class="back-container__title text-center mt-3 no-select">
          <h4>{{ $t('recordingDisclaimer') }}</h4>
          <br />
          {{ $t('allowchatrecording') }}
        </div>
        <div class=" mt-4 recording-disclaimer-actions">
          <button
            class="btn btn-secondary mr-3"
            type="button"
            @click="answerRecordingDisclaimer(false)"
          >
            {{ $t('no') }}
          </button>

          <button
            class="btn btn-primary ml-3"
            type="button"
            @click="answerRecordingDisclaimer(true)"
          >
            {{ $t('yes') }}
          </button>
        </div>
      </div>
    </custom-modal>
    <loading :active="loading" :is-full-page="true" color="#F37E00" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { modulesName } from '@/store';
import CustomModal from '@/components/basics/modal/CustomModal.vue';
import RocketChatService from '@/services/RocketChatService';
import Chat from '@/components/chat/components/Chat.vue';
import ConfirmationModal from '@/components/basics/modal/ConfirmationModal.vue';
import {
  getProcessChatCode as _getProcessChatCode,
  answerChatRecordingDisclaimer as _answerChatRecordingDisclaimer,
  dropWaitingRoom as _dropWaitingRoom
} from '@/services/PublicService';

import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import Loader from '@/components/Loader';

export default {
  name: 'TurnChat',
  components: {
    Loader,
    CustomModal,
    Chat,
    ConfirmationModal
  },
  data() {
    return {
      loading: false,
      chatLoading: false,
      error: null,
      model: {},
      externalChatRoom: null,
      rocketChat: RocketChatService,
      showDropModal: false,
      test: true
    };
  },
  async created() {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.processChatCode();
      }
    });
    await this.processChatCode();
  },
  watch: {
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
  },
  computed: {
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    requireRecordingDisclaimerApproval() {
      const result = !(
        !this.model.waitingRoom ||
        this.model.waitingRoom.channels.length === 0 ||
        this.model.waitingRoom.channels[0].recordCommunication !== null
      );
      return result;
    },
    sessionCompleted() {
      const result =
        this.model.waitingRoom &&
        [
          WaitingRoomStatus.SessionFinished,
          WaitingRoomStatus.SessionCompleted,
        ].includes(this.model.waitingRoom.waitingRoomStatus);

      return result;
    },
    startMessage() {
      if (!this.model) return null;

      return `${this.$t('turn')}: #${`${
        this.model.serviceQueueStartingLetter || ''
      }${this.model.turnNumber}`.trim()}, ${this.$t('location')}: ${
        this.model.locationName
      }, ${this.$t('queue')}: ${this.model.serviceQueueName}`;
    },
  },
  methods: {
    async processChatCode() {
      this.loading = true;

      const turnId = Number(this.$route.params.id);
      const code = this.$route.query.code;

      await _getProcessChatCode({
        turnId: turnId,
        code: code,
      })
        .then(async (resp) => {
          this.model = resp.data || {};
          if (this.model.language) {
            this.$i18n.locale = this.model.language;
          }
          const disclaimerAnswer =
            this.model.waitingRoom.channels[0].recordCommunication;

          if (this.model.waitingRoom.channels[0].recordCommunication != null)
            this.onDisclaimerAnswered(disclaimerAnswer);
        })
        .finally(() => (this.loading = false));
    },
    async onDrop() {
      this.loading = true;

      await _dropWaitingRoom(
         this.model.waitingRoom.waitingRoomId).then(() => {
          this.showDropModal = false;
         }).catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        })
        .finally(() => (this.loading = false));
    },
    async answerRecordingDisclaimer(answer) {
      this.loading = true;

      await _answerChatRecordingDisclaimer({
        waitingRoomId: this.model.waitingRoom.waitingRoomId,
        answer: answer,
      })
        .then(async () => {
          this.model.waitingRoom.channels[0].recordCommunication = answer;

          this.onDisclaimerAnswered(answer);
        })
        .finally(() => (this.loading = false));
    },
    async onDisclaimerAnswered() {
      const externalChannel = this.model.waitingRoom?.channels?.find(
        (c) => c.isExternalChat,
      );

      if (externalChannel) {
        this.$turnHub.$emit(
          'joinWaitingRoomGroup',
          this.model.waitingRoom.waitingRoomId,
        );

        this.rocketChat.setAuthenticationInfo(
          this.model.chatUserId,
          this.model.chatAuthToken,
        );

        await this.rocketChat.loadPublicRoom(
          externalChannel.communicationRoomId,
        );

        this.externalChatRoom = this.rocketChat.rooms.find(
          (x) => x.id == externalChannel.communicationRoomId,
        );
      }
    },
    subscribeToEvent(connected) {
      if (!connected) {
        if(this.$turnHub) {
          this.$turnHub.$off('onWaitingRoomStatusChanged');
        }
        return;
      }

      this.$turnHub.$off('onWaitingRoomStatusChanged');
      this.$turnHub.$on('onWaitingRoomStatusChanged', (status) => {
        const invalidStatus = [
          WaitingRoomStatus.AddedSupervisor,
          WaitingRoomStatus.AddedMonitor,
          WaitingRoomStatus.MonitorOut,
          WaitingRoomStatus.SupervisorOut,
        ];

        if (invalidStatus.includes(status)) return;
        this.model.waitingRoom.waitingRoomStatus = status;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.recording-disclaimer-actions {
  .btn {
    width: 140px;
  }
}

::v-deep .manager-chat {
  margin-top: 5px;
  min-height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
  overflow-y: hidden;
  border: 2px solid #f5af41;
  border-radius: 5px;

  .start-conversation-date {
    margin-bottom: 5px;
  }

  .chat-content {
    display: block;
    margin-top: 0 !important;
    min-height: calc(100vh - 460px);
    max-height: calc(100vh - 460px);
  }

  .chat-content::-webkit-scrollbar {
    width: 10px;
  }

  .chat-content::-webkit-scrollbar-track {
    background: #cccccc;
    border-radius: 0px;
    border: 1px solid lightgray;
  }

  .chat-content::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 0px;
  }

  margin-left: 0px !important;

  &.chat {
    width: 100%;
  }

  &.ext .chat-content {
    background-color: #fff2ef;
    margin-top: 10px;
  }

  &.int .chat-content {
    background-color: #ebebeb;
    margin-top: 10px;
  }

  .chat-form {
    bottom: 0;
    height: 70px;
    position: -webkit-sticky;
    position: sticky;
    border-top: 2px rgba(180, 180, 180, 0.4) solid;
  }

  &.ext .chat-form {
    background-color: #fff2ef;
  }

  &.int .chat-form {
    background-color: #ebebeb;
  }

  .chat-content .message-content {
    padding-top: 10px !important;
  }

  .btn-primary {
    color: #fff;
    background-color: #ff8105;
    border-color: #ff8105;
    border-radius: 4px;
    opacity: 1;
    font-size: 16px;
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #fff;
    background-color: #ff8105;
    border-color: #ff8105;
  }

  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff8105;
    border-color: #ff8105;
  }

  .btn-primary:focus,
  .btn-primary.focus {
    color: #fff;
    background-color: #ff8105;
    border-color: #ff8105;
    box-shadow: 0 0 0 0.2rem #ffb164;
  }

  .btn-primary:hover {
    color: #fff;
    background-color: #ff8105;
    border-color: #ff8105;
  }

  .chat-content .start-conversation {
    border-bottom: 2px rgba(180, 180, 180, 0.4) solid !important;
  }

  .chat-content .start-conversation-date,
  .chat-content .message-content.small {
    display: none;
  }



  @media (min-width: 360px) {
    min-height: calc(100vh - 390px);
    max-height: calc(100vh - 390px);

    .col-chat-input {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-chat-submit {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .chat-content {
      min-height: calc(100vh - 440px);
      max-height: calc(100vh - 440px);
    }
  }

  @media (min-width: 576px) {
    min-height: calc(100vh - 370px);
    max-height: calc(100vh - 370px);

    .col-chat-input {
      flex: 0 0 75%;
      max-width: 75%;
    }

    .col-chat-submit {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .chat-content {
      min-height: calc(100vh - 420px);
      max-height: calc(100vh - 420px);
    }
  }

  @media (min-width: 768px) {
    min-height: calc(100vh - 360px);
    max-height: calc(100vh - 360px);

    .col-chat-input {
      flex: 0 0 80%;
      max-width: 80%;
    }

    .col-chat-submit {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media (min-width: 992px) {
    .col-chat-input {
      flex: 0 0 80%;
      max-width: 80%;
    }

    .col-chat-submit {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media (min-width: 1923px) {
    .col-chat-input {
      flex: 0 0 90%;
      max-width: 90%;
    }

    .col-chat-submit {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }

  @media (min-width: 2250px) {
    .col-chat-input {
      flex: 0 0 90%;
      max-width: 90%;
    }

    .col-chat-submit {
      flex: 0 0 10%;
      max-width: 10%;
    }
  }
}
</style>
