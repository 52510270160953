<template>
  <div class="container-md">
    <div class="row">
      <div v-if="canAttachdocuments" class="col-12">
        <h4>{{ $t('attachDocumentsToActiveTurn') }}</h4>
        <hr />
      </div>
      <div v-if="error" class="col-12 text-center text-danger">
        <h3>
          {{ $t('cannotAttachDocumets') }}
        </h3>
        <h4>{{ error }}</h4>
      </div>
      <div
        v-if="!canAttachdocuments && !turnLoading && !error"
        class="col-12 text-center"
        style="color: #f4a63d"
      >
        <h3>
          {{ $t('cannotAttachDocumets') }}
        </h3>
        <h4>{{ $t('documentsHaveAlreadySentAgent') }}</h4>
      </div>
      <div class="col-12">
        <Loader v-if="turnLoading" />
        <UploadDocuments
          v-if="canAttachdocuments"
          :key="uploadDocumentsKey"
          accept=".png,.jpg,.jpeg,.pdf"
          :loading="uploadingDocuments"
          @change="attachDocuments($event)"
        />
      </div>
      <div v-if="!error && !turnLoading" class="col-12">
        <div class="row">
          <div class="col-12 mt-4">
            <h4>{{ $t('uploadedDocuments') }}</h4>
            <hr class="mb-2" />
          </div>
          <!-- <div v-if="canAttachdocuments" class="col-12 mb-3">
            <button
              class="btn btn-primary btn-block"
              :disabled="!canAttachdocuments"
              @click="submitDocuments()"
            >
              <i class="fas fa-paper-plane"></i>
              {{ $t('sendDocumentForValidation') }}
            </button>
          </div> -->
          <div class="col-12">
            <Loader v-if="documentsLoading && !documents.length" />
          </div>
          <div v-if="!documents.length && !documentsLoading" class="col-12">
            <h5
              class="text-center p-5"
              style="background-color: white; border-radius: 4px"
            >
              {{ $t('thereAreNoDocuments') }}
            </h5>
          </div>
          <div class="col-12">
            <loading
              :active="documentsLoading"
              color="#F37E00"
              :is-full-page="false"
            />
            <div class="row">
              <div
                class="col-12 mb-3"
                v-for="document in documents"
                :key="document.id"
              >
                <div class="uploaded-file">
                  <div class="file-container d-flex justify-content-between">
                    <div class="file-content d-flex">
                      <i class="fas fa-file" />
                      <div class="information">
                        <span class="title">
                          {{ document.fileName }}
                        </span>
                        <br />
                        <span v-if="document.file.size > 0">
                          {{ humanFileSize(document.file.size) }}
                        </span>
                      </div>
                    </div>
                    <div v-if="canAttachdocuments" class="file-close">
                      <button class="btn btn-light" @click="onDelete(document)">
                        <i class="fas fa-times" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadDocuments from '../../components/UploadDocuments';
import {
  turnCanAttachDocuments as _turnCanAttachDocuments,
  turnAttachedDocuments as _turnAttachedDocuments,
  turnAttachDocuments as _turnAttachDocuments,
  turnDetachDocument as _turnDetachDocument,
  turnSubmitDocuments as _turnSubmitDocuments,
} from '@/services/PublicService';
import CodeDocumentType from '@/constants/CodeDocumentType';
import Loader from '@/components/Loader';
import { humanFileSize } from '@/utils';

export default {
  name: 'AttachDocuments',
  components: {
    UploadDocuments,
    Loader,
  },
  data() {
    return {
      documents: [],
      turnLoading: false,
      documentsLoading: false,
      canAttachdocuments: false,
      turnId: null,
      error: null,
      uploadingDocuments: false,
      uploadDocumentsKey: 0,
    };
  },
  async created() {
    this.turnId = Number(this.$route.params.id);
    this.turnLoading = true;
    await this.canAttachDocuments();
    this.turnLoading = false;
    this.startCanAttachdocumentsPolling();
    await this.getAttachedDocuments();
  },
  methods: {
    humanFileSize,
    async attachDocuments(files) {
      this.uploadingDocuments = true;
      await _turnAttachDocuments({
        turnId: this.turnId,
        documents: Array.from(files).map((file) => ({
          documentTypeId: CodeDocumentType.TurnDocumentByRequest,
          file,
        })),
      })
        .then(() => {
          this.ShowSuccessToast(this.$t('documentsUploadedSuccessfully'));
        })
        .finally(() => {
          this.uploadingDocuments = false;
          this.uploadDocumentsKey++;
        });
      this.getAttachedDocuments();
    },
    async canAttachDocuments() {
      try {
        const { data } = await _turnCanAttachDocuments(this.turnId);
        this.canAttachdocuments = data;
      } catch (error) {
        let errorMessage = error?.response?.data?.message;
        if (!errorMessage) errorMessage = this.$t('systemError');
        this.error = errorMessage;
      }
    },
    async getAttachedDocuments() {
      this.documentsLoading = true;
      try {
        const { data } = await _turnAttachedDocuments(this.turnId);
        this.documents = data.map((x) => {
          const file = new File([x.content], x.fileName);
          return {
            ...x,
            file,
          };
        });
      } finally {
        this.documentsLoading = false;
      }
    },
    submitDocuments() {
      if(this.documents.length) {
        this.documentsLoading = true;
        _turnSubmitDocuments(this.turnId)
          .then(() => {
            this.ShowSuccessToast(this.$t('documentsSentSuccessfully'));
            this.canAttachdocuments = false;
            this.startCanAttachdocumentsPolling();
          })
          .finally(() => (this.documentsLoading = false));
      }
    },
    async onDelete(attachment) {
      try {
        this.documentsLoading = true;
        await _turnDetachDocument({
          turnId: this.turnId,
          documentId: attachment.documentId,
        });
        this.ShowSuccessToast(this.$t('successfullyDeleted'));
        await this.getAttachedDocuments();
      } catch (error) {
        this.ShowErrorToast(this.$t('systemError'));
      }
    },
    startCanAttachdocumentsPolling() {
      if (this.canAttachdocuments || this.error) return;
      setTimeout(async () => {
        await this.canAttachDocuments();
        this.startCanAttachdocumentsPolling();
      }, 20000);
    }
  },
};
</script>
<style lang="scss">
.btn-primary {
  color: #fff;
  background-color: #ff8105;
  border-color: #ff8105;
  border-radius: 4px;
  opacity: 1;
  font-size: 16px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ff8105;
  border-color: #ff8105;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff8105;
  border-color: #ff8105;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #ff8105;
  border-color: #ff8105;
  box-shadow: 0 0 0 0.2rem #ffb164;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ff8105;
  border-color: #ff8105;
}

.uploaded-file {
  border: 1px solid #f4a63d;
  width: 100%;
  background-color: #fdfaf6;
  min-height: 80px;
  border-radius: 4px;
  padding: 0 15px;

  .title {
    color: #f4a63d;
    font-size: 16px;
  }

  .file-content {
    i {
      font-size: 35px;
      color: #f4a63d;
      margin-right: 15px;
      margin-top: 16px;
    }
    .information {
      margin-top: 12px;
    }
  }
  .file-close {
    button {
      background-color: white;
      margin-top: 12px;
      border-radius: 100%;
      i {
        color: #f4a63d;
        font-size: 25px;
      }
    }
  }
}
</style>
