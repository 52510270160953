<template>
  <div class="container">
    <div class="content">
      <h1>Eliminar cuenta de usuario</h1>
      <p>
        Para eliminar tu cuenta, sigue los siguientes pasos:
        <ol>
          <li>
            Primero, accede al menú principal de la aplicación y selecciona '<b>Configuración</b>'.
            
          </li>
          <li>
            Dentro de la pantalla de configuración, desplázate hacia abajo hasta encontrar la opción '<b>Eliminar cuenta</b>'.
            
          </li>
          <li>
            Al seleccionarla, se te pedirá que confirmes tu decisión. 
            <br />
            <b>NOTA: Recibirás un aviso que te informará sobre la pérdida de acceso a tu cuenta y la eliminación permanente de todos los datos asociados.</b>
          </li>
          <li>
            Una vez que hayas revisado la información, deberás confirmar la eliminación ingresando tu contraseña y seleccionando '<b>Eliminar</b>' para completar el proceso.
          </li>
        </ol>
        <b>La eliminación de la cuenta es irreversible y todos los datos relacionados con tu perfil serán eliminados de forma permanente.</b>
      </p>
      <div>
        <img src="@/public/assets/img/mobile/AccountDeletionStep1.png" alt="Paso 1" />
        <img src="@/public/assets/img/mobile/AccountDeletionStep2.png" alt="Paso 2" />
        <img src="@/public/assets/img/mobile/AccountDeletionStep3_4.png" alt="Paso 3 y 4" />
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/mixins/vue-loading';

export default {
  name: 'AppDeleteAccountGuide',
  components: {},
  mixins: [loading],
};
</script>
<style lang="scss">
  body {
    background-color: #fff !important;
    overflow-y: hidden;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2em;
    text-align: center;
    margin-top: 20px;
  }

  p, ol, li {
    font-size: 1em;
    line-height: 1.5;
    margin-right: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .container {
    padding: 20px;

    & .content {
      padding-bottom: 1rem;
      text-align: justify;
      min-height: calc(100vh - 300px);
      max-height: calc(100vh - 300px);
      overflow-y: auto;
    }

    & img {
      float: left;
      margin-right: 25px;
    }
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: white;
    border-radius: 4px;
    border: 1px solid lightgray;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #ff8105;
    border-radius: 4px;
  }

  @media (min-width: 360px) {
    .content img {
      min-height: 724px;
      max-height: 724px;
      text-align: center;
    }
  }

  @media (min-width: 576px) {
    .content img {
      min-height: 724px;
      max-height: 724px;
    }
  }

  @media (min-width: 768px) {
    .content img {
      min-height: 724px;
      max-height: 724px;
    }
  }

  @media (min-width: 992px) {
    .content img {
      .content img {
        min-height: 724px;
        max-height: 724px;
      }
    }
  }
</style>
