import { apiTurnos } from '@/core/api/';
import { objectToFormData } from '@/utils';

export const getLegalDocument = (payload) =>
  apiTurnos.get('/api/public/legal/document', { params: { ...payload } });

export const turnCanAttachDocuments = (turnId) =>
  apiTurnos.get(`/api/public/turn/${turnId}/attach/documents`);

export const turnAttachedDocuments = (turnId) =>
  apiTurnos.get(`/api/public/turn/${turnId}/documents/list`);

export const turnSubmitDocuments = (turnId) =>
  apiTurnos.post(`/api/public/turn/${turnId}/submit/documents`);

export const turnDetachDocument = (payload) =>
  apiTurnos.post(
    `/api/public/turn/${payload.turnId}/detach/document`,
    payload.documentId,
  );

export const turnAttachDocuments = (payload) => {
  const model = objectToFormData(payload);
  return apiTurnos.post(
    `/api/public/turn/${payload.turnId}/attach/documents`,
    model,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const getProcessChatCode = (payload) =>
  apiTurnos.get(`/api/public/turn/${payload.turnId}/chat/process`, {
    params: { code: payload.code },
  });

export const answerChatRecordingDisclaimer = (payload) =>
  apiTurnos.post(
    `/api/public/turn/${payload.waitingRoomId}/chat/disclaimer`,
    payload,
  );

export const getTurnVideoCallURL = (payload) =>
  apiTurnos.get(`/api/public/turn/notification/video/url`, {
    params: { ...payload },
  });

export const dropWaitingRoom = (payload) => apiTurnos.post(`/api/public/turn/drop/waitingroom`, payload);
