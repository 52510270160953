<template>
  <div id="drop-area" class="upload-file">
    <loading :active="loading" color="#F37E00" :is-full-page="false" />
    <input
      :key="key"
      ref="inputFile"
      :accept="accept"
      multiple
      type="file"
      @change="onFileChange($event)"
    />
    <button
      class="btn btn-outline-primary"
      :style="{
        backgroundColor: '#fdfaf6',
        color: '#f4a63d',
        borderColor: '#f4a63d',
      }"
      @click="uploadFile()"
    >
      <i class="bi bi-cloud-arrow-up" />
      {{ $t('pressDragToUploadDocuments') }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: 'application/pdf',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: 0,
    };
  },
  mounted() {
    let dropArea = document.getElementById('drop-area');

    function highlight() {
      dropArea.classList.add('highlight');
    }

    function unhighlight() {
      dropArea.classList.remove('highlight');
    }

    ['dragenter', 'dragover'].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });
  },
  methods: {
    uploadFile() {
      this.$refs.inputFile.click();
    },
    onFileChange(event) {
      const files = event?.target?.files;
      const allowedExtensions = this.accept.split(',');
      for (const file of files) {
        if (!allowedExtensions.some((x) => file.name.endsWith(x))) {
          this.key++;
          return this.ShowErrorToast(this.$t('invalidDocumentFormat'));
        }

        const maxSize = 10 * 1024 * 1024; // 10 MB in bytes

        if (file.size > maxSize) {
          this.key++;
          return this.ShowErrorToast(this.$t('fileSizeLimitMessage'));
        }
      }

      this.$emit('change', files);
    },
  },
};
</script>
<style lang="scss">
.upload-file {
  width: 100%;
  background-color: #f5f5f5;
  border: 2px dashed #ccc;
  padding: 0 10px;
  height: 120px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  &:hover,
  &.highlight {
    border: 2px dashed #f4a63d;
  }
}

input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
button {
  z-index: 1;
}
</style>
