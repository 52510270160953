<template>
  <div class="container-fluid">
    <div class="row justify-content-center mb-3">
      <img
        src="@/public/assets/img/logo.svg"
        alt="Icono"
        style="height: 3rem"
      />
    </div>

    <div class="yellow-line-container">
      <div class="row">
        <div class="col-12">
          <hr class="yellow-line" />
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <h1 class="welcome-text">{{ $t('welcome') }}</h1>
    </div>

    <div class="row justify-content-center mt-3">
      <h4 class="instruction-text">{{ $t('accessCodeMessage') }}</h4>
    </div>

    <div class="row justify-content-center mt-4">
      <div class="col-6 d-flex">
        <input
          ref="accessCode"
          v-model="accessCode"
          type="number"
          class="access-code form-control"
          :placeholder="$t('accessCode')"
          @input="limitInputLength"
        />
        <div class="ml-2">
          <button
            class="btn btn-primary"
            :disabled="accessCode.length <= 0"
            @click="submitCode"
          >
            {{ $t('getInto') }}
          </button>
        </div>
      </div>
    </div>

    <!-- Texto de aviso -->
    <div class="row justify-content-center mt-4">
      <p class="small-text text-muted">{{ $t('accessCodeInfo') }}</p>
    </div>
  </div>
</template>

<script>
import { getTurnVideoCallURL as _getTurnVideoCallURL } from '@/services/PublicService';
import loading from '@/mixins/vue-loading';
import { SELFSERVICE } from '@/config';

export default {
  name: 'SelfService',
  mixins: [loading],
  data() {
    return {
      accessCode: '',
    };
  },
  methods: {
    limitInputLength() {
      const accessCode = this.$refs.accessCode;
      if (!accessCode || accessCode.tagName !== 'INPUT') {
        return;
      }

      if (accessCode.value.length > 12) {
        this.accessCode = accessCode.value.slice(0, 12);
      }
    },
    async submitCode() {
      this.ShowWait();

      await _getTurnVideoCallURL({
        clientAppointmentId: this.accessCode,
        isForClient: true,
        callback: SELFSERVICE.REDIRECT_URI,
      })
        .then(async (resp) => {
          var data = resp.data || {};
          window.location.href = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message))
        .finally(() => this.HideWait());
    },
  },
};
</script>

<style>
body {
  background-color: rgb(255 255 255) !important;
}

.access-code {
  height: auto;
}

.yellow-line {
  border-top: 8px solid #ffad2d;
}

.welcome-text {
  font-size: 35px; /* Tamaño del texto */
  text-transform: uppercase; /* Convertir texto a mayúsculas */
  font-weight: bold;
}

.instruction-text {
  font-size: 24px; /* Tamaño del texto */
}

.small-text {
  font-size: 14px; /* Tamaño del texto */
}

.btn-primary {
  color: #fff;
  background-color: #ffad2d;
  border-color: #ffad2d;
  border-radius: 4px;
  opacity: 1;
  font-size: 22px;
  padding: 12px 25px 12px 25px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #ffad2d;
  border-color: #ffad2d;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ffad2d;
  border-color: #ffad2d;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #ffad2d;
  border-color: #ffad2d;
  box-shadow: 0 0 0 0.2rem #ffb164;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ffad2d;
  border-color: #ffad2d;
}
</style>
