export const createFile = async (url, name, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: response.headers.get('content-type') || defaultType,
  });
};

export const sort = (array, property) => {
  array.sort((a, b) =>
    a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0,
  );
};

export const buildFormData = (formData, data, parentKey = '') => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    if (Array.isArray(data)) {
      data.forEach((item, index) => {
        buildFormData(
          formData,
          item,
          parentKey
            ? item instanceof File
              ? parentKey
              : `${parentKey}[${index}]`
            : index.toString(),
        );
      });
    } else {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}.${key}` : key,
        );
      });
    }
  } else {
    if (data !== null && data !== undefined) formData.append(parentKey, data);
  }
};

export const objectToFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};
