<template>
  <div class="container-md">
    <div class="container-fluid">
      <div v-html="model.policy"></div>
      <div class="clear"></div>
    </div>
    <br />
  </div>
</template>

<script>
import { getLegalDocument as _getLegalDocument } from '@/services/PublicService';
import loading from '@/mixins/vue-loading';
import ApplicationType from '@/constants/ApplicationType';
import DocumentType from '@/constants/DocumentType';

export default {
  name: 'Policy',
  components: {},
  mixins: [loading],
  data() {
    return {
      model: {
        policy: null,
        companyId: null,
      },
    };
  },
  async mounted() {
    this.ShowWait();

    const lang = this.$route.query?.lang ?? 'es';
    const payload = {
      ApplicationType: this.$route.query?.at ?? ApplicationType.Turnos,
      DocumentType: DocumentType.Policy,
      companyId: this.$route.query?.companyId,
    };

    await _getLegalDocument(payload)
      .then(({ data }) => {
        this.model.policy =
          lang == 'en' ? data.documentContentEn : data.documentContent;
      })
      .catch((error) => this.ShowErrorToast(error.response.data.message))
      .finally(() => {
        this.HideWait();
      });
  },
};
</script>
<style lang="scss">
body {
  background-color: #fff !important;
}
.container-md {
  padding-bottom: 1rem;
  text-align: justify;
}
</style>
